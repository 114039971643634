import type { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';

import { LandingPage } from 'components/pages/LandingPage';
import { useAuth } from 'hooks';
import { ROUTE_DASHBOARD, navigateTo } from 'utils';

export const Home: NextPage = () => {
  const { lang } = useTranslation();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    navigateTo(ROUTE_DASHBOARD, lang);
    return null;
  }

  return <LandingPage />;
};
