import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import useTranslation from 'next-translate/useTranslation';

import { ButtonIcon } from 'components/molecules/ButtonIcon';
import { TFnWithArgs } from 'types';
import { navigateWithParams, ROUTE_DASHBOARD } from 'utils';

interface IOwnProps {
  onClick?: TFnWithArgs;
}

export const LinkHome: React.FC<IOwnProps> = ({ onClick }) => {
  const { t } = useTranslation('common');

  const handleClick = e => {
    onClick?.(e);
    // halt navigation if click was preventDefaulted
    if (!e.defaultPrevented) navigateWithParams(ROUTE_DASHBOARD);
  };

  return (
    <ButtonIcon onClick={handleClick} label={t('home')}>
      <CottageOutlinedIcon fontSize='small' sx={{ mr: { sm: '8px' } }} />
    </ButtonIcon>
  );
};
