import { Column } from 'components/atoms/Column';
import { Title } from 'components/atoms/Title';
import { QueryParamsReady } from 'components/molecules/QueryParamsReady';
import { PageFooter } from 'components/templates/PageFooter';
import { TFC } from 'types';

interface IOwnProps {
  title?: string;
  withFooter?: boolean;
}

export const PageCenteredTemplate: TFC<IOwnProps> = ({ title, children, withFooter = true }) => (
  <QueryParamsReady>
    <Column
      minHeight='100vh'
      justifyContent='center'
      alignItems='center'
      px={{
        xs: 2,
        sm: 0,
      }}
    >
      <Column
        margin='0 auto'
        width='100%'
        maxWidth='100vw'
        flex={1}
        justifyContent='center'
        alignItems='center'
      >
        {title && <Title textAlign={'center'}>{title}</Title>}
        {children}
      </Column>
      {withFooter && <PageFooter />}
    </Column>
  </QueryParamsReady>
);
