import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import useTranslation from 'next-translate/useTranslation';

import { ButtonIcon } from 'components/molecules/ButtonIcon';
import { TFnWithArgs } from 'types';
import { navigateWithParams, ROUTE_COUPONS } from 'utils';

interface IOwnProps {
  onClick?: TFnWithArgs;
}

export const LinkCoupons: React.FC<IOwnProps> = ({ onClick }) => {
  const { t } = useTranslation('common');

  const handleClick = e => {
    onClick?.(e);
    // halt navigation if click was preventDefaulted
    if (!e.defaultPrevented) navigateWithParams(ROUTE_COUPONS);
  };

  return (
    <ButtonIcon onClick={handleClick} label={t('coupons')}>
      <RedeemOutlinedIcon fontSize='small' sx={{ mr: { sm: '8px' } }} />
    </ButtonIcon>
  );
};
