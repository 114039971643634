import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import useTranslation from 'next-translate/useTranslation';

import { ButtonIcon } from 'components/molecules/ButtonIcon';
import { useAuth } from 'hooks/useAuth';
import { navigateTo, ROUTE_SIGN_IN } from 'utils';

export const ButtonSignOut: React.FC = () => {
  const { t, lang } = useTranslation('sign-in');
  const { authLogout } = useAuth();

  const logoutUser = () => {
    authLogout();
    navigateTo(ROUTE_SIGN_IN, lang);
  };

  return (
    <ButtonIcon onClick={logoutUser} label={t('sign_out_short')}>
      <LogoutOutlinedIcon fontSize='small' sx={{ mr: { sm: '8px' } }} />
    </ButtonIcon>
  );
};
