import Box from '@mui/material/Box';

import { Row } from 'components/atoms/Row';
import { ButtonSignIn } from 'components/molecules/ButtonSignIn';
import { ButtonSignOut } from 'components/molecules/ButtonSignOut';
import { ButtonSignUp } from 'components/molecules/ButtonSignUp';
import { CreateOrder } from 'components/molecules/CreateOrder';
import { LanguageSwitcher } from 'components/molecules/LanguageSwitcher';
import { LinkHome } from 'components/molecules/LinkHome';
import { LinkStats } from 'components/molecules/LinkStats';
import { LinkCoupons } from 'components/molecules/LinkCoupons';
import { Logo } from 'components/molecules/Logo';
import { NavSeparator } from 'components/molecules/NavSeparator';
import { useAuth } from 'hooks';
import { navigateWithParams } from 'utils';

interface IProps {
  withLogo?: boolean;
}

export const PageHeader: React.FC<IProps> = ({ withLogo = true }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Row
      flexDirection={{
        xs: 'column',
        lg: 'row',
      }}
      alignItems='center'
      justifyContent='space-between'
      pb={{
        xs: 2,
        lg: 4,
      }}
      width='100%'
    >
      {withLogo && <Logo />}
      <Box
        sx={{
          width: {
            xs: '100%',
            lg: 'auto',
          },
          mt: 0,
          mb: 0,
          m: 0,
          svg: {
            fontSize: {
              xs: '2rem',
              lg: '1.25rem',
            },
          },
        }}
      >
        {isAuthenticated && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <LinkHome onClick={() => navigateWithParams()} />
            <NavSeparator />
            <CreateOrder fullWidth={false} sx={{ mx: { sm: 'auto' } }} />
            <NavSeparator />
            <LinkStats />
            <NavSeparator />
            <LanguageSwitcher />
            <NavSeparator />
            <LinkCoupons />
            <NavSeparator />
            <ButtonSignOut />
          </Box>
        )}
        {!isAuthenticated && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                sm: 'flex-start',
              },
              mb: {
                xs: 2,
                sm: 0,
              },
            }}
          >
            <ButtonSignUp />
            <NavSeparator />
            <ButtonSignIn />
          </Box>
        )}
      </Box>
    </Row>
  );
};
