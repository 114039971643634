import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import useTranslation from 'next-translate/useTranslation';

import { ButtonIcon } from 'components/molecules/ButtonIcon';
import { navigateTo, ROUTE_SIGN_IN } from 'utils';

export const ButtonSignIn: React.FC = () => {
  const { t, lang } = useTranslation('sign-in');

  const handleClick = () => navigateTo(ROUTE_SIGN_IN, lang);

  return (
    <ButtonIcon onClick={handleClick} label={t('sign_in')}>
      <LoginOutlinedIcon fontSize='small' sx={{ mr: '8px' }} />
    </ButtonIcon>
  );
};
