import Box from '@mui/material/Box';

import { Column } from 'components/atoms/Column';
import { Link } from 'components/atoms/Link';
import { PageTemplate } from 'components/templates';
import { PageHeader } from 'components/templates/PageHeader';
import { ROUTE_SIGN_UP } from 'utils';

export const LandingPage: React.FC = () => {
  return (
    <PageTemplate>
      <PageHeader />
      <Column>
        <Column
          sx={{
            mb: 4,
            alignItems: {
              xs: 'center',
              sm: 'flex-start',
            },
            textAlign: {
              xs: 'center',
              sm: 'left',
            },
            h1: { margin: 0, textAlign: 'center' },
          }}
        >
          <Box component='h1'>Shopaholic Diary</Box>
          <Box sx={{ fontWeight: 300 }} component='h1'>
            The Ultimate Online Purchase Tracker
          </Box>
        </Column>

        <p>
          Shopaholic Diary is the essential application for all online shopping enthusiasts.
          <br />
          This platform enables you to:
        </p>
        <ul>
          <li>Store and manage details about your online purchases</li>
          <li>Filter purchases based on various criteria</li>
          <li>Track your expenses through clear graphs and tables</li>
          <li>Keep an overview of all your shopping and orders in one place</li>
          <li>Monthly/weekly overview</li>
          <li>Filtering of active, completed or canceled orders</li>
        </ul>
        <p>Reasons to choose Shopaholic Diary:</p>
        <ul>
          <li>Easy to use</li>
          <li>Helps you track your spending</li>
          <li>Comprehensive features</li>
          <li>Free to use</li>
        </ul>
        <p>
          <Link href={ROUTE_SIGN_UP}>Sign up</Link> for Shopaholic Diary today and start tracking
          your online purchases!
        </p>
      </Column>
    </PageTemplate>
  );
};
