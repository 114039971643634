import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import useTranslation from 'next-translate/useTranslation';

import { ButtonIcon } from 'components/molecules/ButtonIcon';
import { navigateTo, ROUTE_SIGN_UP } from 'utils';

export const ButtonSignUp: React.FC = () => {
  const { t, lang } = useTranslation('sign-up');

  const handleClick = () => navigateTo(ROUTE_SIGN_UP, lang);

  return (
    <ButtonIcon onClick={handleClick} label={t('sign_up')}>
      <PersonAddOutlinedIcon fontSize='small' sx={{ mr: '8px' }} />
    </ButtonIcon>
  );
};
