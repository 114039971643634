import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

interface IButtonProps extends ButtonProps {
  label?: string;
  tooltipTitle?: string;
  xsHideText?: boolean;
}

export const ButtonIcon = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ children, onClick, label, tooltipTitle = '', xsHideText = true, sx, ...props }, ref) => (
    <Tooltip title={tooltipTitle} placement='top'>
      <Button
        ref={ref}
        variant='text'
        onClick={onClick}
        size='medium'
        sx={{
          mt: 0,
          mb: 0,
          m: 0,
          p: {
            xs: 0,
            sm: '6px 8px',
          },
          minWidth: {
            xs: '32px',
            sm: '64px',
          },
          whiteSpace: 'nowrap',
          ...sx,
        }}
        {...props}
      >
        {children}
        <Box
          sx={{ pl: 1, display: { xs: xsHideText ? 'none' : 'inline-block', sm: 'inline-block' } }}
        >
          {label}
        </Box>
      </Button>
    </Tooltip>
  )
);

ButtonIcon.displayName = 'ButtonIcon';
