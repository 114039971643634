import LinkNext from 'next/link';
import Image from 'next/image';

import imgLogo from 'assets/logo/logo.svg';

import { generateUrlWithSearch } from 'utils';
import { Box } from '@mui/material';

export const Logo: React.FC = () => (
  <Box
    sx={{
      mb: {
        xs: 1,
        sm: 0,
      },
      maxWidth: {
        xs: '400px',
        sm: '280px',
      },
    }}
  >
    <LinkNext href={generateUrlWithSearch()}>
      <Image priority src={imgLogo} alt='Shopaholic Diary Logo' />
    </LinkNext>
  </Box>
);
