import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ButtonProps } from '@mui/material/Button';
import useTranslation from 'next-translate/useTranslation';

import { ButtonIcon } from 'components/molecules/ButtonIcon';
import { useActivityIndicator } from 'hooks';
import { restCreateOrder } from 'rest';
import { IOrder } from 'types';
import { log } from 'utils';
import { routeReplace, ROUTE_ORDER } from 'utils/routes';

interface IOwnProps extends ButtonProps {
  withLabel?: boolean;
  onCreate?: (order: IOrder | null) => void;
}

export const CreateOrder: React.FC<IOwnProps> = ({
  onCreate,
  withLabel = true,
  ...buttonProps
}) => {
  const { t } = useTranslation('common');
  const { showIndicator, hideIndicator } = useActivityIndicator();

  const handleOnClick = async () => {
    showIndicator();
    try {
      const apiOrder = await restCreateOrder();
      onCreate?.(apiOrder);
      if (apiOrder) routeReplace(`${ROUTE_ORDER}/${apiOrder._id}`);
      log.debug('handleOnClick:: order created: ', apiOrder);
      return apiOrder;
    } catch (err) {
      log.error('CreateOrder:: onClick:: err: ', err);
      return err;
    } finally {
      hideIndicator();
    }
  };

  return (
    <ButtonIcon
      onClick={handleOnClick}
      label={withLabel ? t('create') : ''}
      sx={{ mt: 0, mb: 0, p: 0 }}
      tooltipTitle=''
      {...buttonProps}
    >
      <AddCircleOutlineIcon sx={{ mr: { sm: 0.5 } }} />
    </ButtonIcon>
  );
};
