import TranslateIcon from '@mui/icons-material/Translate';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { ButtonIcon } from '../ButtonIcon';
import { navigateTo } from 'utils';

export const LanguageSwitcher = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => setMenuOpen(true);

  const handleClose = () => setMenuOpen(false);

  const handleLocaleChange = (newLocale: string) => {
    const currentPath = router.asPath;
    navigateTo(currentPath, newLocale);
  };

  return (
    <div>
      <ButtonIcon
        ref={anchorRef}
        key='language'
        onClick={handleClick}
        label={t('language')}
        aria-controls='language-menu'
        aria-haspopup='true'
      >
        <TranslateIcon fontSize='small' sx={{ mr: { sm: '8px' } }} />
      </ButtonIcon>
      {anchorRef.current && (
        <Menu
          anchorEl={anchorRef?.current}
          disableScrollLock={true}
          id='language-menu'
          keepMounted
          onClick={handleClose}
          onClose={handleClose}
          open={menuOpen}
          PaperProps={{ sx: { width: '125px' } }}
        >
          <MenuItem onClick={() => handleLocaleChange('en')}>{t('english_language')}</MenuItem>
          <MenuItem onClick={() => handleLocaleChange('sk')}>{t('slovak_language')}</MenuItem>
        </Menu>
      )}
    </div>
  );
};
